var render = function render(){var _vm=this,_c=_vm._self._c;return _c('vue-editor',{class:{
    'ligne-text': _vm.classComponent == 'ligne-text',
    'produit-description': _vm.classComponent == 'produit-description',
    objet:
      _vm.classComponent == 'objet' || _vm.classComponent == 'description-facture',
    'description-facture': _vm.classComponent == 'description-facture',
    disabledComponent: _vm.disabledComponent
  },on:{"text-change":_vm.readInput},model:{value:(_vm.content),callback:function ($$v) {_vm.content=$$v},expression:"content"}})
}
var staticRenderFns = []

export { render, staticRenderFns }